/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import {
  PageLayout,
  SlideInUpHeading,
  SlideUpContent,
  CTALink,
  Banner,
} from 'components';
import { graphql } from 'gatsby';
import { CatIcon } from 'assets/tsx/cat-icon';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ReactMarkdown from 'react-markdown';
import { SeoType } from '../pages/index';

gsap.registerPlugin(ScrollTrigger);

interface OurBrandPageTypes {
  data: {
    OurBrandPage: {
      edges: {
        node: {
          id: number;
          title: string;
          description: string;
          callToAction: {
            link: string;
            title: string;
          };
          quotation: {
            content: string;
          };
          seo: SeoType;
          banner: {
            id: string;
            title: string;
            image: {
              publicURL: string;
              extension: string;
              childImageSharp: {
                fluid: {
                  base64: string;
                  aspectRatio: number;
                  src: string;
                  srcSet: string;
                  sizes: string;
                };
              };
            };
          }[];
          contentSection: {
            title: string;
            summaryContent: string;
          };
        };
      }[];
    };
  };
}

const OurBrand: FC<OurBrandPageTypes> = ({ data }) => {
  const ourBrandQuery = data.OurBrandPage.edges[0].node;
  const {
    title,
    description,
    seo,
    callToAction: { link, title: callToActionTitle },
    quotation: { content: quotation },
    contentSection,
  } = ourBrandQuery;

  return (
    <PageLayout
      title={title}
      quotation={quotation}
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.publicURL}
      className="container"
    >
      <div className="brand-page">
        <h4 className="page-title"> &gt; {title}</h4>
        <div className="page-description">
          <ReactMarkdown>{description}</ReactMarkdown>
        </div>
        <div className="hero-banner brand-page-wrapper">
          <div className="hero-banner-slider">
            <Banner
              PageBanner={ourBrandQuery}
              BannerLength={ourBrandQuery.banner.length}
            />
          </div>
        </div>
        <div className="brand-page-content">
          <div className="brand-page-heading-icon-container">
            <div className="brand-page-content-heading">
              <h2
                className="section_large_heading"
                aria-label={contentSection.title}
              >
                <SlideInUpHeading delaySec={0.3}>
                  {contentSection.title}
                </SlideInUpHeading>
              </h2>
            </div>
            <div className="brand-page-content-icon">
              <CatIcon />
            </div>
          </div>

          <SlideUpContent className="brand-page-content-details">
            <ReactMarkdown>{contentSection.summaryContent}</ReactMarkdown>
          </SlideUpContent>
        </div>
        <CTALink to={link}>{callToActionTitle}</CTALink>
      </div>
    </PageLayout>
  );
};

export default OurBrand;

export const query = graphql`
  query OurBrandPageQuery {
    OurBrandPage: allStrapiOurBrand {
      edges {
        node {
          id
          title
          description
          callToAction {
            link
            title
          }
          seo {
            metaDescription
            metaTitle
            shareImage {
              publicURL
              childImageSharp {
                gatsbyImageData(width: 400, height: 400)
              }
            }
          }
          banner {
            id
            title
            image {
              publicURL
              extension
              childImageSharp {
                fluid(maxWidth: 3500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          contentSection {
            summaryContent
            title
          }
          quotation {
            content
          }
        }
      }
    }
  }
`;
